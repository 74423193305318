
.app__chef-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 3rem;
}
.app__fire {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    max-width: 100%;
overflow: hidden;
}



.app__wrapper_img3 img{
    margin: 0 100px 50px 0;
    height: auto !important;
    width: 100%;
    max-width: 1200px ;
}






@media screen and (min-width:2500px) {
    .app__wrapper_img3 img{
        margin: 50px 100px 50px 0;
        height: auto !important;
        width: 100%;
        max-width: 2000px !important;
       
    }
    .app__fire {
        margin: 0;
        padding: 0;
        display: flex;
        
    }
    .app__wrapper_img3 {
        padding: 0;
        margin: 0;
         height: auto;
         width: 100%;
         max-width: 1500px ;
         justify-content: center;
         align-items: center;
         display: flex;
     }
     .app__wrapper_info {
        text-align: center;

    }
 
 }
 @media screen and (min-width:1921px) {
    .app__wrapper_img3 img{
        margin: 50px 100px 50px 0;
        height: auto !important;
        width: 100%;
        max-width: 1700px !important;
        object-fit: contain;
    }
    .app__fire {
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
    }
    .app__wrapper_info {
        text-align: center;

    }
 
 }
 @media screen and (max-width:1920px) {
    .app__wrapper_img3 img{
        padding: 50px 0 50px 0;
        height: auto !important;
        width: 100%;
        max-width: 1500px !important;
    }
    .app__wrapper_img3 {
        padding: 0;
        margin: 0;
         height: auto;
         width: 100%;
         max-width: 1500px ;
         justify-content: center;
         align-items: center;
         display: flex;
     }
 }
 
 @media screen and (max-width:1366px) {
    .app__fire {
        display: flex;
        flex-direction: column;
    }
    .app__wrapper_img3 img{
        padding: 0 0px 0px 0;
        height: auto;
        width: 100%;
        margin-right: 0;
    }
    .app__wrapper_info {
        text-align: center;

    }
    .app__wrapper_info h1 {
        font-size: 50px;
        line-height: normal;
     }
      .app__wrapper_img3 {
       
       margin: 50px 0 0 0;
        height: auto;
        width: 100%;
        max-width: 1200px ;
        justify-content: center;
        align-items: center;
    }
 }

@media screen and (max-width:920px) {
    .app__wrapper_img3 img{
        margin: 50px 0 50px 0;
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
    .app__wrapper_info h1 {
        font-size: 35px;
        text-align: center;
     }
  
     div.app__wrapper_info {
        text-align: center;
        justify-content: center;
        align-items: center;
        padding-left: 0 !important;
        margin: 0;

     }
 }
@media screen and (max-width:450px) {
    .app__wrapper_img3 img {
        margin: 50px 0 10px 0;
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
    .app__wrapper_info h1 {
       font-size: 45px;
    }
}