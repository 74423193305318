

.app__aboutus {
  position: relative;
  max-width: 100%;
overflow: hidden;

}

.app__aboutus-overlay {
  position: absolute;
  inset: 0;

}

.app__aboutus-content h1 {
  font-family: var(--font-base);
  color: var(--color-golden);
  font-size: 64px;
  line-height: 83.2px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
}

.app__aboutus-overlay img {
  width: 200px;
  height: 220px;
  z-index: 0;
}

.app__aboutus-content {
  width: 100%;
  z-index: 2;
}

.app__aboutus-content_about {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;

  text-align: right;
}

.app__aboutus-content_history {
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  text-align:left;
}

.app__aboutus-content_setar {
  margin: 2rem 4rem;
}
.app__aboutus-content_setar img {
  height: 820px;
}

.app__aboutus-content_history p 
.app__aboutus-content_about p {
    margin: 2rem 0;
    color: var(--color-grey);
}
.app__aboutus-content_about img {
  transform: rotate(180deg);
}

@media screen and (max-width: 5000px){
    .app__aboutus-content_knife img {
        height: 1610px;
    }
}
@media screen and (min-width: 2500px){
.app__aboutus-content_setar {
  padding: 140px 0 140px 0;
  }
  .app__aboutus-content_setar img{
    width: 100%;
    height: 1000px;
 }
}
@media screen and (max-width: 1920px){

    .app__aboutus-content_setar img{
      width: 100%;
      height: 800px;
   }
}
@media screen and (max-width: 1366px){
    .app__aboutus-content {
        flex-direction: row;
    }
    .app__aboutus-content_setar img{
       width: 100%;
       height: auto;
       max-width: 150px;
    }
    .app__aboutus-overlay img {
      width: 100%;
      height:auto;
      max-width: 150px;
  }
  .app__aboutus-content h1 {
    font-family: var(--font-base);
    color: var(--color-golden);
    font-size: 45px;
    line-height: 83.2px;
    letter-spacing: 0.04em;
    text-transform: capitalize;
  }
  .app__aboutus-content_history p {
    text-align: left !important;
  }
  .app__aboutus-content p {
    text-align: right;
    
  }
 }
@media screen and (max-width: 1365px){
    .app__aboutus-overlay img {
        width: 100%;
        height:auto;
    }
    .app__aboutus-content {
      flex-direction: column;
      display: flex;
    }
    .app__aboutus-content p {
      text-align: right;
      font-size: 15px;
    }
}
@media screen and (max-width: 920px){
    .app__aboutus-overlay img {
        width: 100%;
        height:auto;
    }
    .app__aboutus-content {
      flex-direction: column;
      display: flex;
    }
    .app__aboutus-content p {
      text-align: right;
      font-size: 15px;
    }
}

@media screen and (max-width: 450px){
  .app__aboutus {
    flex-direction: column;
    
  }
  .app__aboutus-overlay img {
      width: 100%;
      height:auto;
      padding-bottom: 100px;
  }

  .app__aboutus-content h1 {
    font-family: var(--font-base);
    color: var(--color-golden);
    font-size: 30px;
    line-height: 83.2px;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    display: flex;
    text-align: left;
  }
  .app__aboutus-content p {
    text-align: left;
    font-size: 12px
    ;
  }

  .app__aboutus-content_about {
    display: flex;
    align-items: center;
  }
  .app__aboutus-content {
    flex-direction: column;
  }

  
}
@media screen and (max-width: 768px) {
  .app__aboutus-content_about p,h1 {
    text-align: left;
  }
}
