.app__event {
    max-width: 100%;
overflow: hidden;
}



.app__findus_img img {
    max-width: 800px;
    margin-right: 50px;
    background-color: transparent !important;
    margin-bottom: 30px;
}

.app__findus_img img {
    margin: 0 100px 50px 0;
    height: auto !important;
    width: 100%;
    max-width: 1200px ;
}


@media screen and (min-width:2500px) {
    .app__findus_img img {
        margin: 0 100px 50px 0 !important;
        padding-right: 50px ;
        height: 100%;
        width: 100%;
        max-width: 1400px;
        object-fit: contain;
    }

 }
 @media screen and (max-width:2499px) {
     .app__findus_img img {
         margin: 0 100px 50px 0 !important;
         padding-right: 50px ;
         height: 100%;
         width: 100%;
         max-width: 1100px;
         object-fit: contain;
     }
 
  }
  @media screen and (max-width:1920px) {
    .app__findus_img img {
        margin: 0 50px 50px 0 !important;
        padding-right: 0px ;
        height: 100%;
        width: 100%;
        max-width: 1300px;
        object-fit: contain;
    }
    #event {
        display: flex;
        flex-direction: column;
    }

 }


 @media screen and (max-width:1366px) {
    .app__findus_img img {
        padding: 0 0 0 0 !important;
        margin: 0 0 0 0 !important;
        height: 100%;
        width: 100%;
        max-width: 1200px;
        object-fit: contain;
    }
    .app__findus_img {
        margin: 50px 0 0 0;
        height: auto;
        width: 100%;
        max-width: 1200px ;
        justify-content: center;
        align-items: center;
        margin-bottom: 50px;
    }
    #event {
        display: flex;
        flex-direction: column;
    }
    .app__wrapper_info {
        padding: 0 0 0 50px !important;
    }
    .app__wrapper_info h1{
       margin-bottom: 8px !important;
    }

 }

@media screen and (max-width:920px) {
    .app__findus_img img {
        
        height: 100%;
        width: 100%;
        object-fit: cover;
        
    }
    .app__findus_img {
        justify-content: center;
        display: flex;
        transform: scale(1.0);
        
        margin: 50px 0 50px 0;
    }
    .app__wrapper_info h1{
        text-align: left !important;;
    }
 }


@media screen and (max-width:450px) {
    .app__findus_img img {
        margin: 50px 0 50px 0 !important;
        padding: 0 0 0 0 !important;
        height: 100%;
        width:100%;
        object-fit: contain;
        max-width: 300px
    }
    .app__findus_img {
        justify-content: center;
        display: flex;
        transform: scale(1.2);
        
        margin: 50px 0 50px 0;
    }
 }