.app__music {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 100%;
overflow: hidden;
}

.app__music_dotar {
    margin: 0 100px 50px 0;
    height: auto;
    width: 100%;
    max-width: 600px;
}

.app__music_dotar img {
    max-width: 600px;
    margin-right: 110px;
}


.app__chef-sign p:first-child {
    font-family: var(--font-base);
    font-weight: 400;
    font-size: 32px;
    line-height: 41px;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    color: var(--color-golden)
}



@media screen and (min-width:2500px) {

    .app__music_dotar {
        margin: 0 100px 50px 0;
        height: auto;
        width: 100%;
        max-width: 800px;
    }
    .app__music_dotar img {
        margin: 0 100px 50px 0 !important;
        padding-right: 50px ;
        height: 100%;
        width: 100%;
        max-width: 1400px;
        object-fit: contain;
    }
}

@media screen and (max-width:1920px) {

    .app__music_dotar {
        margin: 0 100px 50px 0;
        height: auto;
        width: 100%;
        max-width: 600px;
    }
}

@media (min-width: 992px) {
    .large-device-margin {
      margin-left: 15%;
      margin-right: 0;
    }
  }


@media screen and (max-width:1366px) {
    .app__music_dotar img {
        display: flex;
        height: auto;
        padding: 0 0 0 0 !important;
        margin: 0 0 0 0 !important;
        height: 100%;
        width: 100%;
        max-width: 550px;
        object-fit: contain;
    }

    .app__music_dotar {
        padding: 0;
        margin: 0;
        width: 200%;
        height: auto;


    }

    .app__wrapper_music1 {
        margin-left: 80px;
    }

    .app__wrapper_info h1 {
        font-size: 50px;
    }

    .app__wrapper_music1 h1 {
        font-size: 50px;
    }

}

.app__energy_img {
    width: 100%;
    justify-content: center;
    margin: 0 0 0 0;
}


@media screen and (max-width:920px) {
    .app__music_dotar img {
        height: 900px !important;
        margin: 30px 0 30px 0;
        width: 100%;
        object-fit: contain;
        padding: 0 0 0 0 !important;
        
    }
    .app__music_dotar{
        display: flex;
        justify-content: center;
        transform: scale(1.1);
    }
  
    .app__music {
        display: flex;
        flex-direction: column;
        width: 100%;
       
    }
   
    .app__wrapper_music1 {
        text-align: center;
        justify-content: center;
        align-items: center;
        margin-left: 0 !important;
    }
    .app__wrapper_music1 h1{
        text-align: center;
        justify-content: center;
        align-items: center;
        padding-left: 0;
        margin-left: 0;
        line-height: normal;
    }

}

@media screen and (max-width:450px) {
    .app__music_dotar {
        height:100%;
        width: 100%;
        margin: 0;
        padding: 0;
    }

    .app__music_dotar img {
        margin: 0 0 0 0 !important;
        padding-right: 0;
        height: 500px !important;
        width: 100%;
        object-fit: contain;
    }

    .app__wrapper_music1 h1 {
        font-size: 30px;
    }

    .app__wrapper_info {
        padding: 0 0 0 0;
    }
}