.app__header {
    background:var(--color-black);
    max-width: 100%;
overflow: hidden;
}


.app__header-h1 {
    font-family: var(--font-base);
    color: var(--color-golden);
    font-size: 64px;
    line-height: 83.2px;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    max-width: 100%;
overflow: hidden;
}

.app__wrapper_img img{
    max-width: 480px !important
}

@media screen and (min-width: 2500px) {

    .moripic img {
        max-width: 700px !important
    }
    .app__header-h1 {text-align: left;}
}

@media screen and (max-width: 2499px) {

    .moripic img {
        max-width: 500px !important
    }
    .app__header-h1 {text-align: left;}
}
@media screen and (max-width: 1920px) {

    .moripic img {
        max-width: 450px !important
    }
    .app__header-h1 {text-align: left;}
}


@media screen and (max-width: 1366px) {

    .moripic img {
        max-width: 380px !important
    }
    .app__header-h1 {
        font-family: var(--font-base);
        color: var(--color-golden);
        font-size: 45px;
        line-height: 70.2px;
        letter-spacing: 0.04em;
        text-transform: capitalize;
    }

}
@media screen and (max-width: 920px) {
    .app__header-h1 {
       text-align: center;
    }
    .app__wrapper_img img{
        max-width: 480px !important
    }
    .app__header-h1 {text-align: left !important;}
    
}
@media screen and (max-width: 450px) {
    .app__header-h1 {
        font-family: var(--font-base);
        color: var(--color-golden);
        line-height: 95px;
        letter-spacing: 0.04em;
        text-transform: capitalize;
    }

    .header h1 {
        font-size: 30px !important;
    }
 
    .app__wrapper_img img{
        max-width: 480px !important
    }
}