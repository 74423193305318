.section__padding {
  padding: 4rem 6rem;
}

.flex__center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app__bg {
  background: url('./assets/bg.webp');
  background-position: center;
  background-size: cover;
  background-repeat: repeat;
  background-attachment: fixed;
  -webkit-background-attachment: fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  


}
@media screen and (max-device-width: 1024px){
  .app__bg {
    background: url('./assets/bg.webp');
    background-position: center;
    background-size: cover;
    background-repeat: repeat;
    background-attachment: scroll;
  
  
    
  
  
  }
}
.app__bglogo {
  background: url('./assets/bglogo.webp');
  background-position: center;
  background-size: contain;
  background-repeat:repeat;
  background-attachment:fixed;
  -webkit-background-attachment: fixed;


    
}

.app__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  min-height: 100vh;
}

.app__wrapper_info {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.app__wrapper_img {
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-left: 2rem;
}

.app__wrapper_img-reverse {
  justify-content: flex-start;
  margin-right: 2rem;
}

.app__wrapper_img img {
  width: 100%;
}

.custom__button {
  width: 140px;
  height: 40px;
  border: none;
  border-radius: 10px;
  background: linear-gradient(to right,#2a2928,#121212,#050505,#77530a,#ffd277,#e1aa3d);
  background-size: 250%;
  background-position: left;
  color: #dcca87;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition-duration: 1s;
  overflow: hidden;
  border: #dcca87 2px solid;
 
}

.custom__button::before {
  position: absolute;
  color: #dcca87;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 97%;
  height: 90%;
  border-radius: 8px;
  transition-duration: 1s;
  background-color: rgba(0, 0, 0, 0.72);
  background-size: 200%;
}
a, a:visited, a:hover, a:active {
  color: inherit;
}


.custom__button:hover {
    background-position: right;
  transition-duration: 1s;
  color: #050505;
}

.custom__button:hover::before {
  background-position: right;
  transition-duration: 1s;
}

.custom__button2 {
  background-color: var(--color-crimson);
    color: var(--color-black);
    font-family: var(--font-base);
    font-weight: 700;
    letter-spacing: 0.04em;
    line-height: 28px;
    font-size: 16px;
    padding: 0.1rem 0.5rem;
    margin-right: 10px;
    border-radius: 1px;
    border: none;
    outline: none;
    cursor: pointer;
}

.p__cormorant {
  font-family: var(--font-alt2);
  color: var(--color-white);
  font-weight: 700;
  letter-spacing: 0.08em;
  text-transform:uppercase;
  font-feature-settings: 'tnum' on, 'lnum' on;
  line-height: 29.9px;
  font-size: 18px;
}

.p__opensans {
  font-family: var(--font-alt2);
  color: var(--color-white);
  font-weight: 400;
  letter-spacing: 0.04em;
  line-height: 28px;
  font-size: 16px;
}

.headtext__cormorant {
  font-family: var(--font-base);
  color: var(--color-golden);
  font-size: 64px;
  line-height: 83.2px;
  letter-spacing: 0.04em;
  text-transform: capitalize;
}


@media screen and (min-width: 2500px) {
  .app__wrapper {
    flex-direction: row;
  }

  .app__wrapper_img {
    margin: 0 100px 0 100px;

  }

  .app__wrapper_img-reverse {
    margin: 0 0 5rem 0;
  }

  .app__wrapper_img img {
    width: 100%;
    height: auto;
  }
  
}


@media screen and (max-width: 1150px) {
  .app__wrapper {
    flex-direction: column;
  }

  .app__wrapper_img {
    margin: 5rem 0 0 0;
  }

  .app__wrapper_img-reverse {
    margin: 0 0 5rem 0;
  }

  .app__wrapper_img img {
    width: 100%;
  }
}

@media screen and (max-width: 850px) {
  .section__padding {
    padding: 4rem;
  }
}

@media screen and (max-width: 650px) {
  .section__padding {
    padding: 4rem 2rem;
  }
  
  .p__cormorant {
    font-size: 21px;
  }

  .p__opensans {
    font-size: 14px;
  }
}

@media screen and (max-width: 450px) {
  .p__opensans {
    font-size: 12px;
  }

  .p__cormorant {
    font-size: 19px;
  }

  .headtext__cormorant {
    font-size: 45px;
    line-height: 70px;
  }
}


.impressum {
  padding: 5px 10px 5px 10px !important;
  color: #ffd277;
  background-color: #050505;
}