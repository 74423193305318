.app__energy {
    flex-direction: column;
   
    position: relative;
    display: flex;
    max-width: 100%;
overflow: hidden !important;

}

.app__energy-title1 {
    margin-bottom: 2rem;
    text-align: center;
} 
 
.energy_button{
    margin:0 auto;
    display:block;
}

.app__energy-menu {
    width: 100%;
    margin: 2rem 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
}


.app__energy-menu_heading {
    font-family: var(--font-base);
    font-weight: 600;
    font-size: 45px;
    line-height: 58px;
    letter-spacing: 0.04em;
    color: var(--color-white);
}
.app__energy_context {
    padding-right: 50px ;
}

.app__energy_img {
    width: 410px;
    margin: 0 2rem;
}


.app__energy_img img {
    height: 650px !important;
    margin-right: 70px
}

 @media screen and (min-width:2500px){
    .app__energy-menu_items {
        margin:2rem;
        padding: 2rem;
    }
    .app__energy_img {
        margin:2rem;
        padding: 2rem;
        
    }
    .app__energy_img img{
        margin-bottom: 200px;
        
    }
    .app__energy_context {
        margin: 200px 50px 0 150px;
    }
 
 
    
 }
  @media screen and (max-width:2499px){

    .app__energy-title1 {
        padding-top: 100px;
    }
 
    .app__energy_img img{
        margin-bottom: 150px;
        padding: 0;
        width: 100%;
        height: auto;
        max-width: 1000px;
    }
    .app__energy_img {
        padding: 0;
        margin:0;

    }
    .app__energy_context {
        padding: 0 50px 0 50px;
    }
 }

 @media screen and (max-width:1920px){

    .app__energy-title1 {
        padding-top: 100px;
    }
 
    .app__energy_img img{
        margin-bottom: 150px;
        width: auto;
    }
    .app__energy_context {
        padding: 0 50px 0 50px;
    }
 }

 
 @media screen and (max-width:1366px){
    .app__energy {
        display: flex;
        flex-direction: column;
    }
    .app__energy-menu {
        width: 100%;
        margin: 2rem 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .app__energy_img {
        display: flex;
        justify-content: center;
        margin: 0 0 0 0;
    }
    .app__energy_img img {
        height: auto !important;
        margin: 30px 0 30px 0; 
        max-width: auto;
        height: auto;
    }
   
    .app__energy_context {
        padding: 0 50px 0 50px;
    }
    .app__energy-title1 h1 {
        font-size: 50px;
    }
    .app__energy-title p{
        text-align: center;
    }
    .app__energy-title1 {
        padding-top: 50px;
    }

    
 }

 @media screen and (max-width:920px){
    .app__energy-menu {
        flex-direction: column;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .app__energy_img {
        width: 100%;
        justify-content: center;
        margin: 0 0 0 0;
    }
    .app__energy_img img {
        height: 900px !important;
        margin: 30px 0 30px 0;
        width: 100%;
        object-fit: contain;
    }
    .energy_button{
        display: flex ;
        justify-content: center;
        margin-bottom: 20px;
    }
    

    .app__energy_context {
        padding: 0 0 0 0 ;
    }

    .app__energy-menu {
        flex-direction: column;
        margin: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .app__energy-title1 h1 {
        text-align: center;
    }
 }

 @media screen and (max-width:450px) {
    .app__energy_img {
        width: 100%;
        justify-content: center;
        margin: 0 0 0 0;
    }
    .app__energy_img img {
        height: 500px !important;
        margin: 30px 0 30px 0;
        width: 100%;
        object-fit: contain;
    }
  
    .app__energy_context p{
        display: flex;   
     text-align: left;
     justify-content:left;
     
    }
    .app__energy-title1 h1  {
            font-size: 30px;
    }
    .app__energy-title p{
        text-align: center;
    }
    .energy_button{
        display: flex ;
        justify-content: left;
        margin-bottom: 20px;
    }
 }