@import url('https://fonts.googleapis.com/css2?family=Cormorant+Upright:wght@300;400;500;600;700&family=Open+Sans:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200&display=swap');
:root {
  --font-base: 'Cormorant Upright', serif;
  --font-alt: 'Open Sans', sans-serif;
  --font-alt2: 'Montserrat', sans-serif;
  
  
  --color-golden: #DCCA87;
  --color-black: #000000;
  --color-gray: #545454;
  --color-crimson: #F5EFDB;
  --color-grey: #AAAAAA;
  --color-white: #FFFFFF;
}
::-webkit-scrollbar {
  width: 10px; /* Set the width of the scrollbar */
  background-color: black;
}

::-webkit-scrollbar-thumb {
  background-color: var(--color-golden); /* Set the background color of the thumb */
  border-radius: 4px; /* Set the border radius of the thumb */
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-gray); /* Set the background color of the thumb on hover */
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior:smooth;
 
  
}

a {
  color: unset;
  text-decoration: none;
}

.slide-bottom {
  -webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
