.app__video {
    height: 100vh;
    position: relative;
    background-color: black;
    max-width: 100%;
overflow: hidden;

}
#video {
    background:#070606 !important
}

.app__video video {
    width: 100%;
    height: auto;
    object-fit:cover;
}

@media screen and (max-width:820px){
    .app__video {
        height: 100vh;
        position: relative;
        background-color: black;
        
    }
    #video {
        background:#070606 !important
    }
    
    .app__video video {
        width: 100%;
        height: auto;
        object-fit:cover;
    }
}

.player-wrapper {
    position: relative;
    padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
    background-color: black;
  }
  
  .react-player {
    position: absolute;
    top: 0;
    left: 0;
  }