 
    .app__yoga_img img{
        max-width: 800px;
        margin:0 50px 0 50px;
        background-color: transparent !important;
        margin-bottom: 30px;
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
    

.app__yoga {
    max-width: 100%;
    overflow: hidden;
    
}




@media screen and (min-width: 2500px) {

    .app__yoga_img img{
        
        max-width: 1000px;
        margin: 0 20px 0px 100px;
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
    .app__wrapper_info {
        text-align: left !important;
    }
    
}
@media screen and (max-width: 2499px) {

    .app__yoga_img img{
        
        max-width: 1000px;
        margin: 0 20px 0px 100px;
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
    .app__wrapper_info {
        text-align: left !important;
    }
    
}
@media screen and (max-width: 1700px) {
    .app__yoga_img img {
        padding: 0 0 0 0 !important;
        margin: 0 0 0 0 !important;
        height: 100%;
        width: 100%;
        object-fit: contain;
        margin-left: 30px !important;
    }
    .app__yoga_img{
        max-width: 500px;
        margin-right: 0;
    }
}

@media screen and (max-width: 1366px) {
 
    .app__wrapper_info {
        padding-right: 110px;
        
    }
    .app__yoga_img img{
        
        max-width: 550px;
        padding: 0 0 0 50px;
        margin: 0 0 0 0;
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
    
}

@media screen and (max-width: 920px) {
 
    
    .app__yoga_img img  {
        margin-left: 0 !important;
        padding-left: 0 !important;
        height: 100%;
        width: 130%;
        

    }
    .app__yoga_img {
        display: flex;
        justify-content: center;
        transform: scale(1.2);
       margin-top: 40px;
       margin-right: 40px;

        
    }
    .yoga {
        text-align: center;
        justify-content: center;
        align-items: center;
        padding-left: 0 !important;
        margin-left: 0 !important;
    }
    .app__wrapper_info {
        margin-left: 0 ;
        padding-left: 0 !important;
    }
    
    .yoga_button {
        margin:0 auto;
        display:block;
        margin-bottom: 20px;
    }
    
}
@media screen and (max-width: 450px) {
 
    div.app__wrapper_info {
     padding-left: 0 !important;
        
    }
    div.app__wrapper_info h1 {
       font-size: 30px;
       line-height: 3rem;
           
       }
    .app__yoga_img img  {
        margin: 0 0 50px 0;
        height: 100%;
        width: 100%;
        object-fit: contain;
        margin-left: 0 !important;
    }
   .app__yoga_img {
    max-width: 100%;
        transform: scale(1);
        margin: 0 !important;
        padding: 0 !important;
        object-fit: contain;
   }
}


