.app__massage_img img {
    margin: 0 100px 50px 0;
    height: auto !important;
    width: 100%;
    max-width: 1200px ;
}

.app__massage {
    max-width: 100%;
    overflow: hidden;
    
}


@media screen and (min-width:2500px) {
    .app__massage_img img {
        margin: 0 100px 50px 0;
        height: 100%;
        width: 100%;
        max-width: 1000px;
        object-fit: contain;
    }
 }
@media screen and (max-width:2499px) {
    .app__massage_img img {
        margin: 0 100px 50px 0;
        height: 100%;
        width: 100%;
        max-width: 1000px;
        object-fit: contain;
    }
 }
 @media screen and (max-width:1700px) {
    .app__massage_img img {
        padding: 0 0 0 0 !important;
        margin: 0 0 0 0 !important;
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
    .app__massage_img {
        max-width: 600px;
        margin-right: 50px;
    }
    .app__wrapper_info h1 {
        font-size: 40px;
        margin-bottom: 0 !important;
    }
 }

 @media screen and (max-width:1366px) {
    .app__massage_img img {
        padding: 0 0 0 0 !important;
        margin: 0 0 0 0 !important;
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
    .app__massage_img {
        max-width: 500px;
    }
    .app__wrapper_info {
        padding: 0 0 0 50px !important;
        
    }
    .app__wrapper_info h1{
       margin-bottom: 8px !important;
       font-size: 40px !important;
    }
 }

@media screen and (max-width:920px) {
    .app__massage_img img {
        margin: 0 0 20px 0;
        height: 100%;
        width: 100%;
        object-fit: contain;
    }
    .app__massage_img {
        margin: 50px 0 60px 0;
        transform: scale(1.33);

    }
    .app__wrapper_info h1{
        text-align: left !important;;
    }
 }


@media screen and (max-width:450px) {
    .app__massage_img img {
        margin: 0 0 0 0 !important;
        padding: 0 0 0 0 !important;
        height: 100%;
        width:100%;
      
    }
    .app__massage_img {
        max-width: 100%;
        transform: scale(1);
        margin: 0;
        padding: 0;
        object-fit: contain;
    }
    div.app__massage_img{
        width: 100%;
        height: auto;
        padding: 0;
        margin: 0 0 0 0;
    }
    .app__wrapper_info h1 {
        font-size: 30px !important;
    }
 }