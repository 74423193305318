.app__navbar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background:var(--color-black);
    padding: 0 2rem;
    align-items: center;
    max-width: 100%;
position: sticky;
top: 0;
z-index: 7;
}

.app__navbar-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}



.app__navbar-links {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin: 0;
}

.app__navbar-links li {
    margin: 0 1rem;
    cursor: pointer;
}
.app__navbar-links a:hover , a:active {
    color: var(--color-golden);
}

.app__navbar-links a:link:visited {
    color: var(--color-golden);
}

.app__navbar-links li:hover {
    color: var(--color-grey);
}

.app__navbar-smallscreen {
    display: none;
}

.app__navbar-login {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  
}

.app__navbar-login a {
    margin: 0 1rem;
    text-decoration: none;
    transition: 0.5s ease;
}

.app__navbar-login a:hover {
    border-bottom: 1px solid var(--color-golden) ;
}

.app__navbar-login div {
    width: 1px;
    height: 30px;
    background-color: var(--color-grey);
}

.app__navbar-smallscreen_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: var(--color-black);
    transition: 0.5s ease;
    z-index:5;
    
}

.app__navbar-smallscreen_overlay .overlay__close {
    font-size: 27px;
    color: var(--color-golden);
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 20px;
}

.app__navbar-smallscreen_links {
    list-style: none;
}

.app__navbar-smallscreen_links li {
    margin: 1rem;
    cursor: pointer;
    line-height: 3rem;
    color: var(--color-golden);
    font-size: 1.5rem;
    text-align: left;
    
    font-family: var(--font-base);
}

.app__navbar-smallscreen_links li:hover {
    color:white;
}
@media screen and (max-width:4000px) {
   .app__navbar-logo img {
    width: 210px;
   }
}

@media screen and (max-width:1600px) {
    .app__navbar-links a{
        display: flex;
        font-size: 11px;
    }
    .app__navbar-links {
        margin: 0;
    }
    .app__navbar-smallscreen {
        display: none;
        align-items: center;
        text-align: center;
    }
    .app__navbar-smallscreen_links {
        display: flex;
        align-items: center;
        text-align: center;
        flex-direction: column;
    }
    .app__navbar-login a{
        font-size: 12px;
    }
}

@media screen and (max-width:1366px) {
    .app__navbar-links a{
        display: none;
        
    }
    .app__navbar-smallscreen {
        display: flex;
        align-items: center;
        text-align: center;
    }
    .app__navbar-smallscreen_links {
        display: flex;
        align-items: center;
        text-align: center;
        flex-direction: column;
    }
}

@media screen and (max-width:920px) {
   .app__navabr {
    padding: 1rem;
   }
   .app__navbar-login{
    display: flex
   }
   .app__navbar-logo img {
    width: 110px;
   }
   .app__navbar-links a{
    display: none;
}
.app__navbar-smallscreen {
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
}
.app__navbar-smallscreen_links {
    display: flex;
    align-items: center;
    text-align: center;
    flex-direction: column;
}
}
@media screen and (max-width:450px) {
    .app__navbar {
        padding: 0 0 0 0 !important;
        margin: 0 0 0 0 !important;
        display: flex;
        justify-content: flex-start;

    }
    .app__navbar-links {
        display: none;
    }
    .app__navbar-smallscreen {
        overflow: hidden;
        display: flex;
        align-items: left;
        text-align: left;

    }
    .app__navbar-smallscreen_links {
        display: flex;
        align-items: center;
        text-align: center;
    }
    .app__navbar-login {
        display: flex;
    }
    .custom__button2 {
        background-color: var(--color-crimson);
        color: var(--color-black);
        font-family: var(--font-base);
        font-weight: 400;
        letter-spacing: 0.04em;
        line-height: 12px !important ;
        font-size: 11px !important;
        padding: 0.3rem 0.5rem !important;
        margin-right: 10px;
        border-radius: 0px;
        border: none;
        outline: none;
        cursor: pointer;
    }
}

